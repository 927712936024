import { fetchApi } from "@/utils/apiCall";

export const saveUnsaveAPI = async (actionName, entityIds, lang, type = "saved_property", groupId = '') => {
  try {
    const response = await fetchApi({
      lang,
      baseUrl: process.env.NEXT_PUBLIC_AUCTION_API_URL_V2,
      subUrl: actionName,
      method: "POST",
      reqBody: {
        userId: Number(window.localStorage.getItem("userId")) ?? null,
        entityIds: entityIds,
        type: type,
        groupId: groupId
      },
    });
    const responseObject = {
      data: response,
    }
    return responseObject;
  } catch (error) {
    return error?.response
  }
};
