import { fetchApi } from "@/utils/apiCall"

export const getHelpNumbers = async (lang, setState=()=>{}) => {
        try {
          const postResponse = await fetchApi({
            lang,
            baseUrl: process.env.NEXT_PUBLIC_API_URL_V1,
            subUrl: `wallet-contact-us`,
            method: 'GET',
          })
    
          if (!postResponse?.status) return
    
          setState({
            auctionNumber: postResponse?.data[0].entity_exfield2,
          })
        } catch (error) {
          console.log('error')
        }
    
}