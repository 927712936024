import IconoGraphy from '@/components/Common/IconoGraphy'
import styles from './style.module.scss'
import useTranslation from 'next-translate/useTranslation'
import { getDateMonthConverted, handleDaysVisibility, numberWithCommas } from '@/utils/utils'
import TooltipRevamp_SRP from '@/components/Common/TooltipRevamp_SRP'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

// Sathish -2022-05-08 - Added the below line for the Infath attributes
const InfathAttributes = ({
  attributes,
  styles: { attribute__image_style, attribute__type_style, attribute__count_style, attribute__name_style },
  auctionData,
  showAttributeName
}) => {

  attributes = attributes?.filter((item) => {
    return item.value
  })

  const { t, lang } = useTranslation('translations-auction')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  useEffect(() => {
    const auctionDate = getDateMonthConverted(t, dayjs(auctionData.startDate).format('DD MMM YYYY'), auctionData.startDate)
    const auctionDateEnd = getDateMonthConverted(t, dayjs(auctionData.endDate).format('DD MMM YYYY'), auctionData.startDate)
    setStartDate(auctionData.status === 'upcoming'
      ? `${t('auctionBidComponent.STARTS_ON')} ${auctionDate}`
      : `${t('auctionBidComponent.STARTED_ON')} ${auctionDate}`)
    setEndDate(`${t('auctionBidComponent.ENDS_ON')} ${auctionDateEnd}`)
  }, [])
  
  const renderOutlineAndDate = (item) => {
    return (
      item.key === 'auction-outline' ? (
        <span className={attribute__count_style} data-unit={item.unit}>
          {item.value}dd
        </span>
      ) : renderDate(startDate, auctionData, lang, t, endDate)
    )
  }
  
  return attributes
    ? attributes?.map((item) => {
        if (item.value) {
          return (
            <div key={item.key} className={attribute__type_style}>
              <div className={attribute__image_style}>
                <IconoGraphy
                  iconClass={`icon-${item.iconClass}`}
                  iconColor={'color_gray'}
                  fontSize={'f_14'}
                ></IconoGraphy>
                {item.key === 'noOfProperties' ? renderNoOfProperties(item, t, lang, attribute__count_style) : renderOutlineAndDate(item)}
              </div>
              {showAttributeName && <div className={attribute__name_style}>{item.name.toLowerCase()}</div>}
            </div>
          )
        }
      })
    : []
}

const renderNoOfProperties = (item, t, lang, attribute__count_style) => {
  const propertyText = item.value > 1 ? ` ${t('user.AUCTION_CARD_PROPERTIES')}` : ` ${t('user.PROPERTY')}`
  return(
    <span className={attribute__count_style} data-unit={item.unit}>
      {lang == 'en' ? (
        <>
          <strong>{numberWithCommas(item.value)}</strong>
          {propertyText}
        </>
      ) : (
        <>
          {t('user.AUCTION_CARD_PROPERTIES')} <strong>{numberWithCommas(item.value)}</strong>
        </>
      )}
    </span>
  )
}

const renderDate = (startDate, auctionData, lang, t, endDate) => (
  startDate && 
      (<div className={styles.pDate}>
        {startDate}
        {auctionData?.durationUnit === 'days' && auctionData.status != 'past' && (
          <span>({handleDaysVisibility(auctionData?.duration, lang, t)})</span>
        )}
        {auctionData?.status != 'past' && (
          <span className={styles.tooltipStyle}>
            <TooltipRevamp_SRP
              tooltipText={startDate}
              tooltipText2={endDate}
              lang={lang == 'ar'}
              offsetLTR={[57, 5]}
              offsetRTL={[-57, 5]}
              srpWidth={true}
            />
          </span>
        )}
      </div>)
)

export default InfathAttributes
