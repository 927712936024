import IconoGraphy from '@/components/Common/IconoGraphy'
import Popup from '@/hoc/components/Popup'
import { downloadFile, getDateConvertedUsingAt, numberWithArabicCommas, numberWithCommas } from '@/utils/utils'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import styles from './e-wallet-Transaction-Details.module.scss'
import { getHelpNumbers } from '@/services/contact-usService'

const EWalletAuctionTransactionDetailPopup = ({ data, onClose, transationDetails }) => {
  const { t, lang } = useTranslation('translations-auction')
  const router = useRouter()
  const [helpNumbers, setHelpNumbers] = useState({
    auctionNumber: '',
  })
  const { transactionHistory } = data

  useEffect(() => {
    if (!helpNumbers?.auctionNumber) {
      getHelpNumbers(lang, setHelpNumbers)
    }
  }, [])

  const wonAuction = transationDetails.transactionStatus == 'captured'
  const latestTransaction = transactionHistory[0]

  const transactionInfoText = {
    on_hold: t('transactionPopup.AMOUNT_IS_BLOCKED'),
    refunded: t('transactionPopup.AMOUNT_IS_REFUNDED'),
    captured: t('transactionPopup.AMOUNT_IS_DEBITED'),
  }

  const amountText = {
    on_hold: t('transactionPopup.IS_BLOCKED'),
    refunded: t('transactionPopup.IS_REFUNDED'),
    captured: t('transactionPopup.IS_DEBITED'),
  }
  return (
    <Popup title={t('transactionPopup.TRANSACTION_DETAILS')} onClose={onClose}>
      <section className={styles.transactionContainer}>
        <div className={styles.transactionTop}>
          <div className={`${styles.statusIcon} ${styles[wonAuction ? 'captured' : latestTransaction?.status]}`}>
            {wonAuction ? (
              <IconoGraphy iconClass={'icon-trophy'} />
            ) : (
              <IconoGraphy iconClass={`icon-${latestTransaction?.iconclass}`} />
            )}
          </div>
          {wonAuction ? (
            <div className={styles.wonAuction}>
              <h2 className={styles.wTitle}>{t('eWallet.WON_AUCTION')}</h2>
              <p className={styles.wTitles}>{getDateConvertedUsingAt(t, transationDetails?.endDate)}</p>
            </div>
          ) : (
            <div className={styles.transactionTopAmount}>
              <h2 className={styles.transactionAmount}>
                {numberWithCommas(latestTransaction?.amount)}{' '}
                <span className={styles.transactionCurrency}>{t('auctionPopup.SAR')}</span>
              </h2>
              <p className={styles.transactionStatus}>{transactionInfoText[latestTransaction?.status]}</p>
            </div>
          )}
        </div>
        <div className={styles.transactionHistory}>
          <h3 className={styles.title}>{t('transactionPopup.TRANSACTION_HISTORY')}</h3>
          <div className={styles.historyCard}>
            {transactionHistory.map((transaction) => (
              <div className={styles.auctionLiist}>
                  <label className={styles.PriceBarList}>
                    {lang == "ar" && <> {amountText[transaction.status]} {numberWithArabicCommas(transaction.amount, lang)} <span className={styles.sarTo}> {t('transactionPopup.SAR')}</span></>}

                    {lang == "en" && <> {numberWithCommas(transaction.amount, lang)} <span className={styles.sarTo}> {t('transactionPopup.SAR')}</span> {amountText[transaction.status]}</>}
                  </label>
                <div className={styles.topHistoryCard}>
                  <p className={styles.textFlow}>{getDateConvertedUsingAt(t, transaction?.createdAt)}</p>
                  {transaction?.status === 'captured' && (
                    <div
                      className={styles.DownloadInvo}
                      onClick={() => {
                        const fileName =
                          transaction?.invoiceUrl &&
                          transaction?.invoiceUrl.split('/')[transaction?.invoiceUrl.split('/').length - 1]
                        downloadFile(transaction?.invoiceUrl, fileName)
                      }}
                    >
                      <IconoGraphy iconClass={'icon-download'} fontSize={'f_12'}></IconoGraphy>
                      {t('eWallet.DOWNLOAD_INVOICE')}
                    </div>
                  )}
                </div>
                {transaction?.status !== 'captured' && (
                  <p className={styles.textFlow}>
                    {t('transactionPopup.TRANSACTION_ID')}: {transaction?.id}
                  </p>
                )}
                {transaction?.entityId && transaction?.status === 'on_hold' && (
                  <Link prefetch={false} href={`/auction/${transaction?.entityId}`} locale={lang}>
                    <div
                      className={`${styles.auctionId} ${styles.textFlow}`}
                      onClick={() => {
                        window.localStorage.setItem('prevPath', router.asPath)
                      }}
                    >
                      {t('common.AUCTION_ID')}: {transaction?.entityId}
                    </div>
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.transactionFooter}>
          <p className={styles.transactionFooterText}>{t('eWallet.NEED_HELP_CALL')}</p>
          <a href={`tel: ${helpNumbers?.auctionNumber}`} className={styles.transactionCall}>
            {helpNumbers?.auctionNumber}
          </a>
        </div>
        <div></div>
      </section>
    </Popup>
  )
}

export default EWalletAuctionTransactionDetailPopup
